import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const BlogCard = ({ blogs, className }) => {
  return (
    <>
      {blogs.map((blog, idx) => {
        return (
          <div className={`${className} col-md-12 gx-3 pb-2`} key={idx}>
            <div className="single-blog-post bg-fffbf5">
              <div className="post-image">
                <Link to={`/blog/${blog.slug}`}>
                  <GatsbyImage
                    image={blog.image.childImageSharp.gatsbyImageData}
                  />
                </Link>
              </div>

              <div className="post-content">
                <ul className="post-meta d-flex align-items-center">
                  <li>
                    {blog.date}
                  </li>
                </ul>
                <h3>
                  <Link to={`/blog/${blog.slug}`}>{blog.title}</Link>
                </h3>
              </div>
              <div className="widget-area pb-3 mt-0">
                <div className="tagcloud">
                  {blog.categories.map(items => {
                    return (
                      <Link className="text-sm" to={`/category/${items.slug}`}>
                        {items.category}
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default BlogCard
