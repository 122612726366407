import React from 'react'

import VectorShape11 from '../../assets/images/shape/vector-shape11.png'

const WeServe = () => {
  return (
    <section className="industries-serve-area bg-fafafb pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Industries We Serve</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-industries-serve-box">
              <div className="icon">
                <i className="flaticon-factory"></i>
              </div>
              Manufacturing
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-industries-serve-box">
              <div className="icon">
                <i className="flaticon-hospital"></i>
              </div>
              Healthcare
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-industries-serve-box">
              <div className="icon">
                <i className="flaticon-investment"></i>
              </div>
              Banking
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-industries-serve-box">
              <div className="icon">
                <i className="flaticon-house"></i>
              </div>
              Real Estate
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-industries-serve-box">
              <div className="icon">
                <i className="flaticon-shopping-basket"></i>
              </div>
              Food &amp; Beverage
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-industries-serve-box">
              <div className="icon">
                <i className="flaticon-family-insurance"></i>
              </div>
              Insurance
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-industries-serve-box">
              <div className="icon">
                <i className="flaticon-bitcoin"></i>
              </div>
              Capital Markets
            </div>
          </div>

					<div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-industries-serve-box">
              <div className="icon">
                <i className="flaticon-earth-day"></i>
              </div>
              Telecom
            </div>
          </div>

        </div>
      </div>

      <div className="vector-shape11">
        <img src={VectorShape11} alt="Vector Shape" />
      </div>
    </section>
  )
}

export default WeServe
