import React from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

const Services = () => {
  return (
    <section className="services-area ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>Services We Can Help You With</h2>
          <p>What we can do for you</p>
        </div>

        <div className="row">
          <div className="col-sm-6 col-xs-12 pt-70">
            <Link to="/services/mobile-application-development/">
              <StaticImage
                src="../../assets/images/services/services-mob-dev.png"
                alt="Mobile App Development"
                placeholder="blurred"
                width={900}
                height={900}
              />
            </Link>
          </div>

          <div className="col-sm-6 col-xs-12 pt-70">
            <Link to="/services/web-development/">
              <StaticImage
                src="../../assets/images/services/services-web-dev.png"
                alt="Web Development"
                placeholder="blurred"
                width={900}
                height={900}
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
