import React from 'react'
import Navbar from '../components/Common/Navbar'
import Footer from '../components/Common/Footer'
import Layout from '../components/App/Layout'
import MainBanner from '../components/Index/MainBanner'
import Services from '../components/Index/Services'
import WhatWeUse from '../components/WhatWeUse/WhatWeUse'
import BlogPost from '../components/Index/BlogPost'
import WhereWeAre from '../components/Index/WhereWeAre'
import WeServe from '../components/Index/WeServe'
import HowWeHelp from '../components/Index/HowWeHelp'
import { graphql, useStaticQuery } from 'gatsby'
import NeedHelp from '../components/NeedHelp/NeedHelp'

const query = graphql`
  {
    strapiSeos(slug: { eq: "home" }) {
      Meta {
        title
        description
        keywords
      }
      TwitterMeta {
        card
      }
    }
  }
`

const Home = () => {
  const data = useStaticQuery(query)
  const {
    strapiSeos: { Meta: metaTags, TwitterMeta },
  } = data

  return (
    <Layout metaTags={metaTags} twitterCard={TwitterMeta}>
      <Navbar />
      <MainBanner />
      <Services />
      <HowWeHelp />
      <WeServe />
      <WhatWeUse />
      <BlogPost />
      <WhereWeAre />
      <NeedHelp />
      <Footer />
    </Layout>
  )
}

export default Home
