import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'

export const query = graphql`
  {
    strapiNeedHelp {
      title
      description
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const NeedHelp = () => {
  const data = useStaticQuery(query)
  const {
    strapiNeedHelp: { title, description, image },
  } = data

  return (
    <div className="project-start-area ptb-100 bg-e3fbff">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <GatsbyImage
                alt={title}
                image={image.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>{title}</h2>
              <p>{description}</p>

              <Link to="/work-with-us" className="default-btn">
                <i className="flaticon-tick"></i>
                Work With Us <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NeedHelp
