import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import BlogCard from '../BlogContent/BlogCard'
import starIcon from '../../assets/images/star-icon.png'

const query = graphql`
  {
    allStrapiBlog(limit: 6, sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        content
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        categories {
          category
          slug
        }
        author
        date: date(formatString: "MMMM D, YYYY")
      }
    }
  }
`

const BlogPost = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiBlog: { nodes: blogs },
  } = data

  return (
    <section className="blog-area pt-100 pb-70 bg-fffbf5">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="blog" />
            Our Blog
          </span>
          <h2>Latest Valuable Insights</h2>
        </div>

        <div className="row">
          <BlogCard blogs={blogs} className="col-lg-4" />
        </div>

        <div className="text-center">
          <Link to="/blog" className="default-btn">
            <i className="flaticon-view"></i>
            Visit Our Blog <span></span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default BlogPost
