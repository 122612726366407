import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import ReactMarkdown from 'react-markdown'
import * as _ from 'lodash'

export const query = graphql`
  {
    strapiWhatWeUse {
      title
      subTitle
      content
      technologyGroupList {
        imageListGroupItem {
          id
          name
          image {
						url
						alternativeText
					}
        }
        name
      }
    }
  }
`

const WhatWeUse = () => {
  const data = useStaticQuery(query)
  const {
    strapiWhatWeUse: { title, subTitle, content, technologyGroupList },
  } = data

  return (
    <section className="technology-groups-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="Technologies" />
            {subTitle}
          </span>
          <h2>{title}</h2>
          <ReactMarkdown source={content} />
        </div>

        {technologyGroupList.map(({ id, name, imageListGroupItem }) => (
          <div className="row" key={`tech-group-${id}`}>
            <div className="col">
              <h5 className="pb-70 pt-70">{name}</h5>

              <div className="row">
                {imageListGroupItem.map(({ id, image, name }) => (
                  <div className="col-3 technology-icon" key={`tech-${id}`}>
										<img src={_.get(_.first(image), 'url')} alt={_.get(_.first(image), 'alternativeText')} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default WhatWeUse
