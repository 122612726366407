import React from 'react'
import starIcon from '../../assets/images/star-icon.png'

const WhereWeAre = () => {
  return (
    <section className="services-area ptb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="Location" />
            Location
          </span>
          <h2>Where We Are</h2>
        </div>

        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <iframe
						 title="Location"
              src={
                `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.717104589184!2d121.0201419147033!3d14.558163382018536` +
                `!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!` +
                `1s0x3397c9060017383f%3A0x9203528517a343d3!2s88+Corporate+Center!5e0!3m2!1sen!2sph!4v1536907580051`
              }
              width="100%"
              height="450"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhereWeAre
