import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

export const query = graphql`
  {
    strapiHowWeHelpSection {
      title
      content
      howWeHelpList {
        name
        iconClass
        definition
      }
    }
  }
`

export const HowWeHelp = () => {
  const data = useStaticQuery(query)
  const {
    strapiHowWeHelpSection: { title, content, howWeHelpList: list },
  } = data

  return (
    <section className="featured-services-area bg-e3fbff pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{title}</h2>
          <ReactMarkdown source={content} />
        </div>

        <div className="row">
          {list.map(({ name, iconClass, definition }) => (
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-featured-services-box">
                <div className="icon">
                  <i className={iconClass}></i>
                </div>
                <h3>{name}</h3>
                <ReactMarkdown source={definition} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HowWeHelp
